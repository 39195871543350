import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import {withRouter} from 'react-router-dom';
import classes from './Navbar.module.css';
const NAvbar = () => {
    
    return (
        <div style={{marginBottom:"100px"}}>
            <Navbar fixed="top" bg="dark" variant="dark">
            <Navbar.Brand href="#home">
                {/* <img
                    alt=""
                    src="logo.jpg"
                    width="50"
                    height="30"
                    className="d-inline-block align-top"
                />{' '} */}
                    <span className={classes.brand}>Panjab Forbes</span>
                    </Navbar.Brand>
            </Navbar>
        </div>
    )
}
export default withRouter(NAvbar);