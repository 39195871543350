import './App.css';
import Navbar from './UI/Navbar/Navbar';
import React from 'react';
import Caraousel from './UI/Carousel/Carousel';
import NavbarBottom from './UI/Navbar/NavbarBottom';
import Details from './UI/Details';
import { Container } from 'react-bootstrap';
const App=()=> {
  return (
    <div className="App">
      <Container>
        <Navbar/>
        <Caraousel/>
        <Details/>
        <NavbarBottom/>
      </Container>
    </div>
  );
}

export default App;
