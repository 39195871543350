import React from 'react';
import { Container } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import {withRouter} from 'react-router-dom';
const NAvbar = () => {
    
    return (
        <div>
            <Navbar fixed="bottom" bg="dark" variant="dark" >
                <div style={{margin:"auto"}}>
                    <Button variant="success" href="tel:+91-998-890-0406"  
                        style={{marginRight:"30px"}}><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        width="24" height="24"
                        viewBox="0 0 172 172"
                        style={{fill:"#000000"}}><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{mixBlendMode: "normal"}}><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M66.40633,113.6275l-18.963,-18.95583c5.7835,-10.97933 12.52017,-19.77283 20.00933,-27.219c7.44617,-7.48917 16.23967,-14.22583 27.219,-20.00933l19.10633,18.99883l36.722,-5.51833v-39.42383c0,0 -2.35067,0 -7.16667,0c-32.5295,0 -63.597,13.18667 -86.24367,35.58967c-22.403,22.64667 -35.58967,53.71417 -35.58967,86.24367c0,4.816 0,7.16667 0,7.16667h39.50267z"></path></g></g></svg> Call Now</Button>
                    <Button href="https://wa.me/919988900406"
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="success"
                            style={{marginLeft:"30px"}}><i class="fa fa-whatsapp whatsapp-icon"></i> Whatsapp
                    </Button>
                </div>
            </Navbar>
        </div>
        
    )
}
export default withRouter(NAvbar);