import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classes from "./Carousel.module.css";
import { Link } from 'react-router-dom';

const Caraousel = () => {
	const [index, setIndex] = useState(0);
	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};
	return (
		<div style={{ marginTop: "1000x" }}>
			<Row>
				<Col md={0} lg={2}></Col>
				<Col sm={12} md={12} lg={8}>
					<Carousel activeIndex={index} onSelect={handleSelect} className={classes.Carousel}>
						<Carousel.Item interval={3000}>
							<a href="https://themedallion.co/" target="_blank"
								rel="noopener noreferrer">
								<img
									height="250"
									width="20"
									className="d-block w-100"
									src="Medallion.jpg"
									alt="First slide"
								/>
							</a>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<a href="#" target="_blank"
								rel="noopener noreferrer">
								<img
									height="250"
									width="20"
									className="d-block w-100"
									src="MarbellaGrand.jpg"
									alt="Second slide"
								/>
							</a>
						</Carousel.Item>

						<Carousel.Item interval={3000}>
							<a href="#" target="_blank"
								rel="noopener noreferrer">
								<img
									height="250"
									width="20"
									className="d-block w-100"
									src="HLPGaleria.jpg"
									alt="Third slide"
								/>
							</a>

							
						</Carousel.Item>
						{/* <Carousel.Item interval={3000}>
								<a href="https://themedallion.co/" target="_blank"
                            rel="noopener noreferrer">
								<img
									height="250"
									width="20"
									className="d-block w-100"
									src="Medallion.jpg"
									alt="Third slide"
								/>
								</a>

								
							</Carousel.Item> */}

					</Carousel>
				</Col>
				<Col md={0} lg={2}></Col>
			</Row>

		</div>
	);
};

export default Caraousel;
