import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
const details = () => {
    return (
        <div style={{marginTop:"30px", marginBottom:"100px"}}>
            <Card>
                <Card.Body></Card.Body>
            </Card>
        </div>
    )
}
export default details;